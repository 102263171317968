import ApolloClient from 'apollo-boost'
import fetch from 'isomorphic-fetch'
import { getToken } from './token'

export const client = new ApolloClient({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  fetch,
  request: (operation) => {
    const token = getToken()
    if (token) {
      operation.setContext({
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
    }
  },
})
