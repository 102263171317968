import React from 'react'
import { ApolloProvider } from '@apollo/react-hooks'
import { client } from './src/api'
import { Provider } from './src/contexts/user'

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <Provider>{element}</Provider>
    </ApolloProvider>
  )
}
