import Cookies from 'js-cookie'

export const cookieKey = 'lona:token'

export const getToken = () => {
  return Cookies.get(cookieKey)
}

export const setToken = (newToken: string) => {
  return Cookies.set(cookieKey, newToken, {
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
  })
}

export const deleteToken = () => {
  return Cookies.remove(cookieKey)
}
