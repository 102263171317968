import React, { useState, createContext, useEffect } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { getToken, deleteToken, setToken } from '../api/token'

import meQuery from '../api/me.graphql'

const localKey = 'lona:user'

type User = null | {
  id: string
  username: string
  email?: string
  token: string
  githubId?: string
  googleId?: string
  organizations: {
    id: string
    name: string
  }[]
}

export const UserContext = createContext<{
  user: User
  loadingUser: boolean
  setUser: (newUser: User | ((prevUser: User) => User)) => void
}>({
  user: null,
  loadingUser: true,
  setUser: () => {},
})

export const storeContext = (ctx: User) => {
  if (!ctx) {
    delete window.localStorage[localKey]
    deleteToken()
    return
  }
  window.localStorage[localKey] = JSON.stringify({ ...ctx, token: undefined })
  setToken(ctx.token)
}

export const loadContext = (): User => {
  if (typeof window === 'undefined') {
    return null
  }
  const token = getToken()
  if (!token) {
    return null
  }
  try {
    const user = JSON.parse(window.localStorage[localKey])
    return {
      ...user,
      token,
    }
  } catch (err) {
    return null
  }
}

export const Provider = ({ children }) => {
  const [user, setUser] = useState(loadContext())

  const [refreshUser] = useLazyQuery(meQuery, {
    onCompleted(data) {
      setUser(data.getMe)
    },
  })

  useEffect(() => {
    if (user && user.token) {
      refreshUser()
    }
  }, [])

  useEffect(() => {
    storeContext(user)
  }, [user])

  return (
    <UserContext.Provider value={{ user, loadingUser: false, setUser }}>
      {children}
    </UserContext.Provider>
  )
}
