// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-oauth-redirect-tsx": () => import("./../src/pages/oauth-redirect.tsx" /* webpackChunkName: "component---src-pages-oauth-redirect-tsx" */)
}

